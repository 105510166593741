<template>
  <div class="card card-custom zoom ribbon ribbon-clip ribbon-left">
    <div class="card-body py-5 px-3 ribbon ribbon-top ribbon-ver">

      <!--Ribbon Versioning-->
      <div class="ribbon-target p-3" style="top: 12px;">
        <span class="ribbon-inner bg-primary"></span>
        <span class="text-uppercase font-weight-bolder h3">{{this.versionNumber}}</span>
      </div>
      <!--end::Ribbon Versioning-->

      <!--Body-->
      <div class="row w-100 ml-3">
        <!--Info-->
        <div class="col-6">
          <div class="row">

            <!--Badge-->
            <div class="d-flex col-12 align-items-center mb-2">
              <span v-if="publishAssessmentsMaster && idea && idea.ideaStatus.id == 3 && ideaSimpleAssessmentStatusId > 1" class="badge font-weight-bolder text-uppercase p-2 float-left mr-2 text-white" :style="{'background-color': this.ideaSimpleAssessmentStatusColor}">
                {{this.ideaSimpleAssessmentStatus}}
              </span>

              <span v-if="idea && idea.ideaStatus.id == 1" class="badge badge-primary bg-danger font-weight-bolder text-uppercase p-2 float-left text-white">
                {{$t("IDEADETAIL.INBOZZA")}}
              </span>

              <span class="font-weight-bolder text-uppercase text-dark-50 align-start ml-2">{{this.publicationDate}}</span>
            </div>
            <!--end::Badge-->

            <!--Title-->
            <div class="col-12">
              <router-link class="cursor-pointer" :to="{
                  name: 'processModuleIdeaDetail',
                  params: {
                    id: this.ideaId,
                    process_id: process_id,
                    phase_id: phase_id,
                    module_id: module_id,
                    phase_status_id: phase_status_id
                  }
                }"
              >
                <div class="d-flex align-items-center text-dark text-primary font-size-h3 font-weight-bold mr-3" v-on:click="onClick">{{ this.ideaTitle | truncate(50, '...') }}</div>
              </router-link>
            </div>
            <!--end::Title-->
          </div>
        </div>
        <!--end::Info-->

        <!--Actions-->
        <div class="d-flex col-6 align-items-center m-0 p-0">

          <div class="row w-100 align-items-center">

            <div class="m-0 p-0" :class="{
            'col-9 align-items-center': canModify() || canDelete(),
            'col-12 align-items-center': !canModify() && !canDelete()
            }">
              <div class="row w-100 align-items-center m-0 p-0">
                <!--Valutazioni-->
                <div v-if="publishAssessmentsDetail && idea && idea.ideaStatus.id == 3" class="col-4 m-0 border-right-xxl text-right">
                  <span class="text-dark-50 d-none d-xxl-block ml-2">{{$t("PARTIALSPROCESSI.VALUTAZIONI")}}</span>
                  <i class="text-dark-50 fas fa-clipboard d-xxl-none"></i>
                  <h3>{{this.countIdeaSimpleAssessments}}</h3>
                </div>
                <div v-else class="col-4 m-0 text-right"></div>
                <!--end::Valutazioni-->

                <!--Community-->
                <div v-if="$activeFollowing && idea && idea.ideaStatus.id == 3" class="col-4 m-0 border-right-xxl text-right">
                  <span class="text-dark-50 d-none d-xxl-block ml-2">{{$t("PARTIALSPROCESSI.COMMUNITY")}}</span>
                  <i class="text-dark-50 fas fa-heart d-xxl-none"></i>
                  <h3>{{this.countFollowers}}</h3>
                </div>
                <div v-else class="col-4 m-0 text-right"></div>
                <!--end::Community-->

                <!--Comments-->
                <div v-if="activeCommenting && idea && idea.ideaStatus.id == 3" class="col-4 m-0 border-right-xxl text-right">
                  <span class="text-dark-50 d-none d-xxl-block ml-2">{{$t("PARTIALSPROCESSI.COMMENTI")}}</span>
                  <i class="text-dark-50 fas fa-comments d-xxl-none"></i>
                  <h3>{{this.countIdeaComments}}</h3>
                </div>
                <div v-else class="col-4 m-0 text-right"></div>
                <!--end::Comments-->
              </div>
            </div>

            <div v-if="canModify() || canDelete()" class="col-3 text-right m-0 p-0">

              <!--Edit-->                   
              <button v-if="canModify()" type="button" class="btn btn-icon btn-primary m-1" @click="editIdea(ideaId)">
                <i class="fas fa-edit text-white"></i>
              </button>
              <!--end::Edit-->
              <!--Delete-->
              <button v-if="canDelete()" type="button" class="btn btn-icon btn-primary m-1" @click="deleteIdea($event)">
                <i class="fas fa-trash-alt text-white"></i>
              </button>
              <!--end::Delete-->
            </div>
          </div>

        </div>
        <!--end::Actions-->
      </div>
      <!--end::Body-->

    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { alertMixin } from "@/mixins/alert";
import { matrixLight } from "@/mixins/matrixLight";

export default {
  name: "ListIdeaCardVersioning",
  mixins: [alertMixin, matrixLight],
  props: ["idea", "process_id", "phase_id", "phase_status_id", "module_id", "see_tabs", "module", "phase", "idPublicIdea"],

  computed: {
    ...mapGetters([
      "currentUser"
    ])
  },

  data() {
    return {
      moduleContentType: null,
      
      ideaId: 0,
      ideaTitle: '',
      versionNumber: 0,
      publicationDate: '',
      ideaSimpleAssessmentStatusId: 1,
      ideaSimpleAssessmentStatus: '',
      author: [],
      countFollowers: 0,
      countIdeaComments: 0,
      countIdeaSimpleAssessments: 0,
      ideaSimpleAssessmentStatusColor: '#F3F6F9',
      seeTabs: this.see_tabs,
      activeCommenting: false,
      publishAssessmentsMaster: false,
      publishAssessmentsDetail: false
    }
  },

  async mounted() {

    let qs = '?&fks_=isast,auth,asses&cnt_=i2foll,icomm,isimass';
    let endpoint = this.generateUrl('ideas/' + this.idea.id, true);
    //generateUrl is done
    await ApiService.get(this.$apiResourceOpen, endpoint + qs)
    .then(res => {

      this.ideaId = res.data.id;
      this.ideaTitle = res.data.title;
      this.versionNumber = res.data.versionNumber;
      this.author = res.data.author;
      this.countIdeaComments = res.data.countIdeaComments;
      this.countFollowers = res.data.countFollowers;
      this.countIdeaSimpleAssessments = res.data.countIdeaSimpleAssessments;

      if(res.data.publicationDate) {

        let publicationDateObj = new Date(res.data.publicationDate);
        this.publicationDate = publicationDateObj.getDate() + "/" + ('0' + (publicationDateObj.getMonth()+1)).slice(-2) + "/" + publicationDateObj.getFullYear();
      }

      if(res.data.ideaSimpleAssessmentStatus && res.data.ideaSimpleAssessmentStatus.localizations && res.data.ideaSimpleAssessmentStatus.localizations[0]) {

        this.ideaSimpleAssessmentStatusId = res.data.ideaSimpleAssessmentStatus.id ?? 1;
        this.ideaSimpleAssessmentStatus = res.data.ideaSimpleAssessmentStatus.localizations[0].name;
        this.ideaSimpleAssessmentStatusColor = res.data.ideaSimpleAssessmentStatus.color;
      }

      if(this.module) {
        this.moduleContentType = this.module?.contentType;
        this.activeCommenting = this.module.activeCommenting;
        this.publishAssessmentsDetail = this.module.publishAssessmentsDetail;
        this.publishAssessmentsMaster = this.module.publishAssessmentsMaster || (res.data && res.data.assessor && res.data.assessor.id && res.data.assessor.id == this.currentUser.id);
      }
    })
  },

  methods: {
    activeVersioning() {
      return (this.module && this.module.activeVersioning);
    },

    onClick() {
      this.seeTabs = true;
      this.$emit('changeSeeTabs', this.seeTabs);
    },

    canModify() {

      if((this.idea.ideaStatus.id == 1 && this.module && this.module.canVersion) || !this.activeVersioning()) {

        if(this.phase && this.phase.processVotes && this.phase.phaseStatus) {
          let modulesWithVotes = [];
          for (let i = 0; i < this.phase.processVotes.length; i++) {
            if (this.phase.processVotes[i].open && !modulesWithVotes.includes(this.phase.processVotes[i].process2ModuleId)) {
              modulesWithVotes.push(this.phase.processVotes[i].process2ModuleId);
            }
          }

          if (this.phase.phaseStatus.id == 2 && this.author && this.currentUser && this.author.id == this.currentUser.id && this.phase.processVotes.length > 0 && modulesWithVotes.includes(this.module.id)) {
            return true;
          }
        }

        if (this.phase && this.phase.phaseStatus && this.phase.phaseStatus.id == 2 && this.author && this.currentUser && this.author.id == this.currentUser.id) {
          return true;
        }
      }

      return false;
    },

    editIdea(id) {
      if(this.can_access_module("update")) {
        this.$router.push({
            name: "processModuleIdeaEdit",
            params: {
              id: id,
              process_id: this.process_id,
              phase_id: this.phase_id,
              module_id: this.module_id,
            },
          });
      } else {
        this.alertUserModuleAccessBitmaskUpdate(this.moduleContentType);
      }
    },

    //MANAGE MATRIX LIGHT
    can_access_module(action = "") {
      return this.$canAccessModule(this.module, action);
    },

    canDelete() {

      if(this.idea.ideaStatus.id == 1 || !this.activeVersioning()) {

        if(this.phase && this.phase.processVotes && this.phase.phaseStatus) {
          let modulesWithVotes = [];
          for (let i = 0; i < this.phase.processVotes.length; i++) {
            if (this.phase.processVotes[i].open && !modulesWithVotes.includes(this.phase.processVotes[i].process2ModuleId)) {
              modulesWithVotes.push(this.phase.processVotes[i].process2ModuleId);
            }
          }

          if (this.phase.phaseStatus.id == 2 && this.author && this.currentUser && this.author.id == this.currentUser.id && this.phase.processVotes.length > 0 && modulesWithVotes.includes(this.module.id)) {
            return true;
          }
        }

        if (this.phase && this.phase.phaseStatus && this.phase.phaseStatus.id == 2 && this.author && this.currentUser && this.author.id == this.currentUser.id) {
          return true;
        }
      }

      return false;
    },

    canEvaluate() {
      if(this.idea) {
        if (this.phase.open && (this.currentUser.authorities.includes('ASSESSOR') || this.currentUser.authorities.includes('EXPERT'))) {
          return true;
        }

        if (!this.phase.open && (this.currentUser.authorities.includes('ASSESSOR'))) {
          return true;
        }
      }
      return false
    },

    deleteIdea(e) {
      e.preventDefault();
      if(this.can_access_module("delete")) {
        Swal.fire({
          title:  this.$t((this.$handleContentTypeLabel(this.moduleContentType) + "DETAIL.ALERT8TITLE")), //#!urgent: aggiungi module
          text:  this.$t("IDEADETAIL.ALERT8"),
          type: "danger",
          showCancelButton: true,
          confirmButtonText:  this.$t("IDEADETAIL.ALERT8B1"),
          cancelButtonText:  this.$t("IDEADETAIL.ALERT8B2"),
          showCloseButton: true,
          showLoaderOnConfirm: true
        }).then(result => {
          if (result.value) {
            // Cancello l'idea'
            return new Promise((resolve, reject) => {
              this.$isLoading(true);
              ApiService.delete(this.$apiResourceOpen + "/ideas/" + this.idea.id)
                  .then(() => {
                    Swal.fire(
                        this.$t((this.$handleContentTypeLabel(this.moduleContentType) + "DETAIL.ALERT9TITLE")),
                        this.$t((this.$handleContentTypeLabel(this.moduleContentType) + "DETAIL.ALERT9")),
                        "success"
                    );

                    this.$router.push({
                      name: "processModuleIdeaDetail",
                      params: {
                        id: this.idPublicIdea,
                        process_id: this.process_id,
                        phase_id: this.phase_id,
                        module_id: this.module_id,
                        phase_status_id: this.phase_status_id,
                      }
                    });

                    this.$isLoading(false);
                    resolve(1);
                  })
                  .catch(() => {
                    Swal.fire(this.$t("IDEADETAIL.ALERT10TITLE"), "", "info");
                    reject(0);
                  });
            });
          } else {
            Swal.fire(this.$t("IDEADETAIL.ALERT10TITLE"), "", "info");
          }
        });
      } else {
        this.alertUserModuleAccessBitmaskDelete(this.moduleContentType);
      }
    }
  },

  filters:{
    truncate(text, length, clamp){
      clamp = clamp || '...';
      let node = document.createElement('div');
      node.innerHTML = text;
      let content = node.textContent;
      return content.length > length ? content.slice(0, length) + clamp : content;
    }
  }
}
</script>
