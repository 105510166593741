var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom zoom ribbon ribbon-clip ribbon-left"},[_c('div',{staticClass:"card-body py-5 px-3 ribbon ribbon-top ribbon-ver"},[_c('div',{staticClass:"ribbon-target p-3",staticStyle:{"top":"12px"}},[_c('span',{staticClass:"ribbon-inner bg-primary"}),_c('span',{staticClass:"text-uppercase font-weight-bolder h3"},[_vm._v(_vm._s(this.versionNumber))])]),_c('div',{staticClass:"row w-100 ml-3"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"d-flex col-12 align-items-center mb-2"},[(_vm.publishAssessmentsMaster && _vm.idea && _vm.idea.ideaStatus.id == 3 && _vm.ideaSimpleAssessmentStatusId > 1)?_c('span',{staticClass:"badge font-weight-bolder text-uppercase p-2 float-left mr-2 text-white",style:({'background-color': this.ideaSimpleAssessmentStatusColor})},[_vm._v(" "+_vm._s(this.ideaSimpleAssessmentStatus)+" ")]):_vm._e(),(_vm.idea && _vm.idea.ideaStatus.id == 1)?_c('span',{staticClass:"badge badge-primary bg-danger font-weight-bolder text-uppercase p-2 float-left text-white"},[_vm._v(" "+_vm._s(_vm.$t("IDEADETAIL.INBOZZA"))+" ")]):_vm._e(),_c('span',{staticClass:"font-weight-bolder text-uppercase text-dark-50 align-start ml-2"},[_vm._v(_vm._s(this.publicationDate))])]),_c('div',{staticClass:"col-12"},[_c('router-link',{staticClass:"cursor-pointer",attrs:{"to":{
                name: 'processModuleIdeaDetail',
                params: {
                  id: this.ideaId,
                  process_id: _vm.process_id,
                  phase_id: _vm.phase_id,
                  module_id: _vm.module_id,
                  phase_status_id: _vm.phase_status_id
                }
              }}},[_c('div',{staticClass:"d-flex align-items-center text-dark text-primary font-size-h3 font-weight-bold mr-3",on:{"click":_vm.onClick}},[_vm._v(_vm._s(_vm._f("truncate")(this.ideaTitle,50, '...')))])])],1)])]),_c('div',{staticClass:"d-flex col-6 align-items-center m-0 p-0"},[_c('div',{staticClass:"row w-100 align-items-center"},[_c('div',{staticClass:"m-0 p-0",class:{
          'col-9 align-items-center': _vm.canModify() || _vm.canDelete(),
          'col-12 align-items-center': !_vm.canModify() && !_vm.canDelete()
          }},[_c('div',{staticClass:"row w-100 align-items-center m-0 p-0"},[(_vm.publishAssessmentsDetail && _vm.idea && _vm.idea.ideaStatus.id == 3)?_c('div',{staticClass:"col-4 m-0 border-right-xxl text-right"},[_c('span',{staticClass:"text-dark-50 d-none d-xxl-block ml-2"},[_vm._v(_vm._s(_vm.$t("PARTIALSPROCESSI.VALUTAZIONI")))]),_c('i',{staticClass:"text-dark-50 fas fa-clipboard d-xxl-none"}),_c('h3',[_vm._v(_vm._s(this.countIdeaSimpleAssessments))])]):_c('div',{staticClass:"col-4 m-0 text-right"}),(_vm.$activeFollowing && _vm.idea && _vm.idea.ideaStatus.id == 3)?_c('div',{staticClass:"col-4 m-0 border-right-xxl text-right"},[_c('span',{staticClass:"text-dark-50 d-none d-xxl-block ml-2"},[_vm._v(_vm._s(_vm.$t("PARTIALSPROCESSI.COMMUNITY")))]),_c('i',{staticClass:"text-dark-50 fas fa-heart d-xxl-none"}),_c('h3',[_vm._v(_vm._s(this.countFollowers))])]):_c('div',{staticClass:"col-4 m-0 text-right"}),(_vm.activeCommenting && _vm.idea && _vm.idea.ideaStatus.id == 3)?_c('div',{staticClass:"col-4 m-0 border-right-xxl text-right"},[_c('span',{staticClass:"text-dark-50 d-none d-xxl-block ml-2"},[_vm._v(_vm._s(_vm.$t("PARTIALSPROCESSI.COMMENTI")))]),_c('i',{staticClass:"text-dark-50 fas fa-comments d-xxl-none"}),_c('h3',[_vm._v(_vm._s(this.countIdeaComments))])]):_c('div',{staticClass:"col-4 m-0 text-right"})])]),(_vm.canModify() || _vm.canDelete())?_c('div',{staticClass:"col-3 text-right m-0 p-0"},[(_vm.canModify())?_c('button',{staticClass:"btn btn-icon btn-primary m-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.editIdea(_vm.ideaId)}}},[_c('i',{staticClass:"fas fa-edit text-white"})]):_vm._e(),(_vm.canDelete())?_c('button',{staticClass:"btn btn-icon btn-primary m-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteIdea($event)}}},[_c('i',{staticClass:"fas fa-trash-alt text-white"})]):_vm._e()]):_vm._e()])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }