<template>
  <div class="row col-12 justify-content-end pr-0 mr-0" v-if="this.organizationLanguages.length > 1 && this.languages.length > 0">
    <span v-for="l in this.languages" v-bind:key="l.locale" :title="l.locale">
      <div class="symbol symbol-80 symbol-xxl-100 mr-2 align-self-start align-self-xxl-center">
        <button type="button" class="btn btn-icon btn-primary ml-1" :style="getStyle(l.locale, l.primaryColor)" @click="onClick(l.locale)">
          {{l.locale}}
        </button>
      </div>
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ChangeDataLanguages",
  computed: {
    ...mapGetters([
      "getOrganization"
    ])
  },
  props: {
    lang: String,
    localizations: {},
  },
  created() {
    this.organizationLanguages = this.getOrganization?.data?.languages ?? [];
    this.getLanguages();
  },
  data() {
    return {
      languages: [],
      selected_languages: this.lang,
      c_localizations: this.localizations,
      organizationLanguages: []
    }
  },
  watch: {
    localizations: function(value) {
      this.c_localizations = value;
      this.getLanguages();
    },
    lang: function(value) {
      this.onClick(value);
    }
  },
  methods: {
    onClick(locale) {
      this.selected_languages = locale;
      this.$emit('changeLanguage', this.selected_languages);
    },
    getLanguages() {
      this.languages = [];

      this.c_localizations.forEach(element => {

        if(element.recordNative) {

          this.languages[this.getOrganization.data.languages.findIndex(x => x.locale == element.locale)] = this.getOrganization.data.languages.find(x => x.locale == element.locale);
        }
      });

      this.languages = this.languages.filter(function (el) {
        return el != null;
      });
    },
    getStyle(locale, color) {
      return (locale == this.selected_languages) ? ('background-color:' + color + ' !important;') : 'background-color: #bbbbbb !important;';
    }
  }
};
</script>
