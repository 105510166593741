<template>
  <div class="topbar-item" v-b-tooltip.hover.bottom="'Quick panel'">

    <!-- begin::Quick Panel -->
    <div
      id="kt_quick_panel"
      ref="kt_quick_panel"
      class="offcanvas offcanvas-right pt-5 pb-10"
      style="overflow: hidden;"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5"
      >
        <ul
          class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10"
          role="tablist"
        >

          <li class="nav-item" v-for="(phase, index) in phasesWithVote" :key="phase.id">
            <a
              class="nav-link active"
              v-on:click="setActiveTab"
              :data-tab="index"
              data-toggle="tab"
              role="tab"
              aria-selected="true"
            >
               {{ $t("QUICKPANEL.VOTE", { n: index + 1 }) }}
            </a>
          </li>
        </ul>
        <div class="offcanvas-close mt-n1 pr-5">
          <a
            href="#"
            class="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_panel_close"
          >
            <i class="ki ki-close icon-xs text-muted"></i>
          </a>
        </div>
      </div>
      <!--end::Header-->

      <!--begin::Body -->
      <div class="offcanvas-content px-10">
        <div class="tab-content">
          <b-tabs class="hide-tabs" v-model="tabIndex">

            <b-tab active v-for="phasewv in phasesWithVote" :key="phasewv.id">
              <div class="scroll pr-7 mr-n7" id="kt_quick_panel_logs">
                <div class="mt-5 mb-15">
                  <h3>{{process.title}}</h3>
                  <h5 class="font-weight-bold mb-5">{{phasewv.title}}</h5>
                  <!-- RiPETI PER OGNI MODULO VOTO -->
                  <div v-for="voteModule in phasewv.processVotes" :key="voteModule.id">
                    <div>{{voteModule.title}}</div>
                    <!-- RIPETI PER OGNI VOTO -->
                    <div v-for="vote in votes" :key="vote.id">
                      <!-- SOLO SE E' UN MIO VOTO -->
                      <div class="card mb-3 shadow-sm card-stretch h-100"
                           v-if="voteModule.id === vote.processVote.id">
                        <div class="card-header ribbon ribbon-top ribbon-ver py-3">
                          <div class="ribbon-target bg-warning" style="top: -2px; right: 20px;">
                            <i class="fa fa-star text-white"></i>
                          </div>
                          <div class="d-flex align-items-center">
                            <a href="" class="mr-3 btn btn-icon btn-lg px-2">
                          <span class="symbol symbol-circle">
                            <img
                                alt="Pic"
                                :src="(vote.voter.avatar && vote.voter.avatar.uri) ? vote.voter.avatar.uri : $imageOptions.defaultAvatarUrl"
                            />
                          </span>
                            </a>
                            <span class="text-muted font-weight-bold">
                          {{ vote.voter.name }}
                        </span>
                          </div>
                        </div>
                        <div class="card-body py-6">
                          <div class="row">
                            <div class="col-12 align-items-center">
                              <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2">
                                <a :href="'/idea-detail/' + vote.idea.id + '/'  + phasewv.processId + '/' + phasewv.id + '/' + voteModule.process2ModuleId">
                                  <h1 class="font-weight-bold font-size-h4 mb-0">{{vote.idea.localizations.title}}</h1>
                                </a>
                                <p class="mt-3" >
                                  {{ $t("QUICKPANEL.VOTEDON", { n: humanDate(vote.applicationDate) }) }}
                                </p>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="row justify-content-between mt-5">

                                <div class="d-flex flex-column">
                                  <!-- SOLO CROWDFOUNDING -->
                                  <span v-if="vote.voteType.id === 4" class="label label-lg label-light-primary label-inline p-5 mr-2 mt-2 font-weight-bolder" v-b-popover.hover.top="$t('QUICKPANEL.DONATION')">
                                    {{ vote.vote }}€
                                  </span>
                                </div>
                                <div class="d-flex flex-column" v-if="voteModule.open">
                                  <a :href="'/idea-detail/' + vote.idea.id + '/'  + phasewv.processId + '/' + phasewv.id + '/' + voteModule.process2ModuleId">
                                    <span class="label label-lg label-primary label-inline p-5 mr-2 mt-2 font-weight-bolder">{{ $t("QUICKPANEL.EDITVOTE") }}</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Fine ripeti per ogni voto -->
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>

        </div>
      </div>
      <!--end::Body -->
    </div>
    <!-- end::Quick Panel -->
  </div>
</template>

<script>
import moment from "moment";
import KTLayoutQuickPanel from "@/assets/js/layout/extended/quick-panel.js";
import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service";

export default {
  name: "KTQuickPanelManual",
  props: ["process"],
  data() {
    return {
      tabIndex: 0,
      userId: 0,
      store: this.$store,
      phasesWithVote: [],
      votes: {}
    };
  },
  mounted() {
    // Init Quick Offcanvas Panel
    KTLayoutQuickPanel.init(this.$refs["kt_quick_panel"]);
  },
  created() {
    this.userId = this.$store.getters.currentUser.id;
    // setTimeout( function() {
    //   this.phasesWithVote = this.getPhasesWithVote();
    //   this.getVotes();
    //   console.log('done');
    // }, 1000);
    this.phasesWithVote = this.getPhasesWithVote();
    this.getVotes();
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },

    humanDate(date) {
      return moment(date).format("DD/MM/YYYY")
    },

    getPhasesWithVote: function() {
      let pwv = this.process.processPhases.filter(i => i.processVotes.length > 0);
      // console.log('Fasi con voti:', pwv);
      return pwv;
    },

    getVotes() {
      let qs = "voterId.equals="+this.userId;

      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl('idea-votes', false);
        //generateUrl is done
        ApiService.get(this.$apiResourceOpen, endpoint + "?" + qs)
        .then(res => {
          // console.log('voti:', res.data);
          // const allVotes= res.data;
          // const uniqueVotes = Array.from(new Set(allVotes.map(a => a.idea.id)))
          //     .map(id => {
          //       return allVotes.find(a => a.idea.id === id)
          //     })
          // console.log('Voti unici utente 1:', uniqueVotes);
          // this.votes = uniqueVotes;

          // Filtro le idee votate dall'utente
          const ideeVotate = res.data.filter( (i) => {
            return i.voter.id === this.userId
          });
          // console.log('Voti unici utente 2:', ideeVotate);

          // Filtro le localizzazioni delle idee votate dall'utente in funzione della lingua attiva
          const ideeVotateLoc = ideeVotate.map( obj => {
            let loc = obj.idea.localizations.find (obj1 => {
              return obj1.locale === i18nService.getActiveLanguage()
            })
            obj.idea.localizations = loc;
            return obj;
          })
          // console.log('Voti unici utente 3:', ideeVotaleLoc);

          this.votes = ideeVotateLoc;
        })
        .catch(() => {
          reject(0);
        });
      });
    },
  }
};
</script>
