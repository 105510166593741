<template>
    <button type="button" :class="['btn btn-primary rounded', elementClass]">
      <slot>
        <i :class="['pr-0 pb-1', iconClass]"></i>
      </slot>
    </button>
  </template>
  <script>
  export default {
    name: "ButtonMobile",
    props: {
      iconClass: {
        type: String,
        default: "",
      },
      elementClass: {
        type: String,
        default: "",
      }
    },
    data() {
      return {
  
      }
    }
  }
  </script>
  <style scoped>
  
  </style>
  