<template>
  <b-modal
    centered
    hide-backdrop
    hide-footer
    no-stacking
    hide-header
    id="modalContactOwner"
    ref="modalContactOwner"
    content-class="shadow"
    @hidden="resetForm"
  >
    <div class="row my-0">
      <div class="col-12 px-10">
        <!--        <i class="fas fa-handshake text-warning fa-pull-left fa-4x"></i>-->
        <!--begin::Svg Icon-->
        <!--        <inline-svg class="pull-left"-->
        <!--                    src="/assets/media/bipart/btn-collaboration-base.svg"-->
        <!--        />-->
        <!--end::Svg Icon-->

        <div class="d-flex mb-1">
          <svg
            width="15%"
            height="50px"
            viewBox="0 0 15% 15%"
            class="text-warning pull-left"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              xmlns="http://www.w3.org/2000/svg"
              id="hands-helping-solid"
              d="M41.535,16.369H28.591v4.769a6.131,6.131,0,1,1-12.262,0V10.783L10.8,14.1a5.438,5.438,0,0,0-2.648,4.666V22.8L1.342,26.733a2.718,2.718,0,0,0-1,3.721l6.812,11.8a2.726,2.726,0,0,0,3.721,1l8.8-5.084H31.316a5.455,5.455,0,0,0,5.45-5.45h1.362a2.722,2.722,0,0,0,2.725-2.725v-5.45h.681A2.039,2.039,0,0,0,43.578,22.5V18.413A2.039,2.039,0,0,0,41.535,16.369Zm12.577-3.185L47.3,1.382a2.726,2.726,0,0,0-3.721-1L34.773,5.47h-8.7a5.491,5.491,0,0,0-2.887.826l-2.853,1.78a2.707,2.707,0,0,0-1.277,2.308V21.138a3.406,3.406,0,1,0,6.812,0V13.645H41.535A4.772,4.772,0,0,1,46.3,18.413V20.84l6.812-3.934A2.728,2.728,0,0,0,54.112,13.185Z"
              fill="#FFA800"
            ></path>
          </svg>

          <h2 class="text-warning align-self-end">
            {{ $t("IDEADETAIL.MCONTATTAILPROPONENTE") }}
          </h2>
        </div>

        <h4>{{ $t("IDEADETAIL.MTIPOLOGIACOLLABORAZIONELABEL") }}</h4>
        <form class="form mt-5" @submit.stop.prevent="onSubmit">
          <b-form-group>
            <b-form-select
              id="collaboration_type"
              ref="collaboration_type"
              v-model="$v.form.collaboration_type.$model"
              :state="validateState('collaboration_type')"
              :options="options"
              :class="{
                invalid:
                  $v.form.collaboration_type.$dirty &&
                  $v.form.collaboration_type.$invalid,
              }"
              class="rounded-0 border-0 shadow-0 bg-white"
            ></b-form-select>
          </b-form-group>

          <b-form-group
            id="label-nominativo"
            :label="$t('IDEADETAIL.MNOMINATIVO')"
            label-for="nominativo"
          >
            <b-form-input
              id="nominativo"
              :value="nome_e_cognome"
              trim
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="label-email"
            :label="$t('IDEADETAIL.MEMAIL')"
            label-for="email"
          >
            <b-form-input
              id="email"
              :value="user_email"
              trim
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group
            v-if="user_mobile"
            id="label-mobile"
            :label="$t('IDEADETAIL.MMOBILE')"
            label-for="mobile"
          >
            <b-form-input
              id="mobile"
              :value="user_mobile"
              trim
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="label-messaggio"
            :label="$t('IDEADETAIL.MINSERISCIUNMESSAGGIO')"
            label-for="messaggio"
          >
            <vue-editor
              class="form-control h-auto form-control-solid py-4 px-8"
              name="message"
              id="message"
              :editor-toolbar="customToolbar"
              :tag="'textarea'"
              v-model="$v.form.message.$model"
              row="10"
              aria-describedby="message-live-feedback"
              autocomplete="off"
              :state="validateState('message')"
              :class="{
                invalid: $v.form.message.$dirty && $v.form.message.$invalid,
              }"
            >
            </vue-editor>
            <template v-if="$v.form.message.$dirty && $v.form.message.$invalid">
              <span v-if="!$v.form.message.required" class="error pl-5">{{
                $t("IDEADETAIL.MMESSAGGIOOBBLIGATORIO")
              }}</span>
            </template>
          </b-form-group>

          <div class="form-group mb-5 text-left">
            <label class="checkbox m-0">
              <input
                type="checkbox"
                name="contact_accept"
                v-model="contact_accept"
              />
              {{ $t("IDEADETAIL.MACCETTODIESSERERICONTATTATO") }}
              <a href="#"></a><span></span>
            </label>
            <div class="form-text text-muted text-center"></div>
          </div>

          <div class="form-group d-flex flex-wrap mb-0">
            <b-button
              class="btn btn-lg btn-default text-uppercase font-weight-bolder"
              @click="close"
              >{{ $t("IDEADETAIL.MBCHIUDI") }}
            </b-button>
            <b-button
              :disabled="isDisabled"
              class="ml-2 btn btn-lg btn-primary text-uppercase font-weight-bolder"
              @click.prevent="registra"
              id="register_anonymous_submit"
              ref="register_anonymous_submit"
              >{{ $t("IDEADETAIL.MBINVIA") }}
            </b-button>
          </div>
        </form>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { commonMixin } from "@/mixins/common";
import { required, minValue } from "vuelidate/lib/validators";
import { VueEditor } from "vue2-editor";
import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service.js";
// import Swal from "sweetalert2";

export default {
  name: "ContactOwner",
  props: ["idea", "module"],
  mixins: [validationMixin, commonMixin],
  data() {
    return {
      moduleContentType: null,

      user_email: "",
      user_mobile: "",
      contact_accept: false,
      options: [
        {
          value: 0,
          text: this.$t("IDEADETAIL.MTIPOLOGIACOLLABORAZIONEPLACEHOLDER"),
        },
      ],
      form: {
        collaboration_type: 0,
        message: "",
      },
      /*
      |--------------------------------------------------------------------------
      | EDITOR
      |--------------------------------------------------------------------------
      */
      customToolbar: [
        ["bold", "italic", "underline", "link"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    };
  },
  validations: {
    form: {
      collaboration_type: {
        required,
        minValue: minValue(1),
      },
      message: {
        required,
      },
    },
  },
  components: {
    VueEditor,
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser", "getOrganization"]),
    isDisabled: function () {
      return !this.contact_accept;
    },
    nome_e_cognome: function () {
      return this.currentUser.name || this.currentUser.surname
        ? this.currentUser.name + " " + this.currentUser.surname
        : this.currentUser.username;
    },
  },
  async created() {
    await this.getUser();
    await this.getInternalMessageCategory();
    // console.log('Current User:',this.currentUser);

    this.moduleContentType = this.module?.contentType;
  },

  methods: {
    close() {
      this.$refs["modalContactOwner"].hide();
    },
    registra() {
      // console.log("Procedo all'invio 1");
      this.onSubmit();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    resetForm() {
      this.contact_accept = false;

      this.form = {
        collaboration_type: 0,
        message: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    getUser() {
      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl(
          "users-expanded/" + this.currentUser.id,
          true
        );
        ApiService.get(this.$apiResource, endpoint)
          .then(async (res) => {                        
            this.user_email = await res.data.email;            
            this.user_mobile = await res.data.mobile;
            resolve(1);
          })
          .catch(() => {
            reject(0);
          });
      });
    },

    getInternalMessageCategory() {
      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl("internal-message-categories", true);
        ApiService.get(this.$apiResource, endpoint)
          .then((res) => {
            // console.log('Risultato chiamata categorie: ',res.data);
            res.data.forEach((item) => {
              this.options.push({ value: item.id, text: item.name });
            });
            // console.log('Opzioni: ',this.options);
            resolve(1);
          })
          .catch(() => {
            reject(0);
          });
      });
    },

    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.$refs["collaboration_type"].focus();
        return;
      }

      if (!this.contact_accept) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["register_anonymous_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      let registrationData = {
        internalMessageCategoryId: this.$v.form.collaboration_type.$model,
        message: this.$v.form.message.$model,
        applicantId: this.currentUser.id,
        ideaId: this.idea.id,
        // applicantEmail: 'info@danilofolli.it'
        applicantEmail: this.user_email,
        locale: i18nService.getActiveLanguage(),
      };

      // Code for test
      /*
      console.log("registrationData: ", registrationData);
      this.$emit("ownerMailed");
      console.log('Evento emesso dalla modale')
      this.$refs["modalContactOwner"].hide();
      console.log('Modale chiusa')
      */
      // End for test

      /**/
      return await new Promise((resolve, reject) => {
        var endpoint = this.generateUrl(
          "/internal-messages/contact-idea-author-by-email",
          false,
          false
        );
        ApiService.post(this.$apiResourceOpen + endpoint, registrationData)
          .then(() => {
            this.$emit("ownerMailed");
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            this.$refs["modalContactOwner"].hide();
            resolve(1);
          })
          .catch((err) => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            this.manageAxiosErrors(err, this.moduleContentType);
            reject(0);
          });
      });
      /**/
    },
  },
};
</script>

<style scoped>
#modalContactOwner___BV_modal_header_ {
  display: none !important;
}
.error {
  color: #ff0000;
}
.invalid {
  border-color: #ff0000;
}
</style>
